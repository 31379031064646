// Constants:
import { OBJECT_STORE } from '../../constants/indexedDB/objectStores'

const ACTION_TYPES = {
  GLOBAL: {
    ROOT: 'ACTION_TYPES.UPDATE.GLOBAL.ROOT',
    BOUNDS: {
      ROOT: 'ACTION_TYPES.UPDATE.GLOBAL.BOUNDS.ROOT',
      FOOTER: 'ACTION_TYPES.UPDATE.GLOBAL.BOUNDS.FOOTER'
    },
    CONTEXT_MENU: {
      ROOT: 'ACTION_TYPES.UPDATE.GLOBAL.CONTEXT_MENU.ROOT',
      TARGET: 'ACTION_TYPES.UPDATE.GLOBAL.CONTEXT_MENU.TARGET'
    },
    EXTENSIONS: 'ACTION_TYPES.UPDATE.GLOBAL.EXTENSIONS'
  },
  INDEXEDDB: {
    ROOT: 'ACTION_TYPES.UPDATE.GLOBAL.INDEXEDDB.ROOT',
    OBJECT_STORE: {
      ROOT: 'ACTION_TYPES.UPDATE.GLOBAL.INDEXEDDB.OBJECT_STORE.ROOT',
      [ OBJECT_STORE.CORE ]: `ACTION_TYPES.UPDATE.GLOBAL.INDEXEDDB.OBJECT_STORE.${ OBJECT_STORE.CORE }`,
      [ OBJECT_STORE.EXTENSIONS ]: `ACTION_TYPES.UPDATE.GLOBAL.INDEXEDDB.OBJECT_STORE.${ OBJECT_STORE.EXTENSIONS }`
    }
  }
}

// Exports:
export default ACTION_TYPES
