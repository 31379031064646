// Packages:
import React from 'react';


// Functions:
const Navbar = () => {
  return (
    <div>
      
    </div>
  );
};


// Exports:
export default Navbar;
