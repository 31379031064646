// Exports:
export enum OBJECT_STORE {
  CORE = 'core',
  EXTENSIONS = 'extensions'
}

export enum OBJECT_STORE_CORE_IDS {
  EXTENSIONS = 'extensions',
  LAYOUT = 'layout'
}

export enum EXTENSION_TYPE {
  MUSIC = 'music',
  TODO_LIST = 'todo_list',
  // TIMER = 'timer'
}
