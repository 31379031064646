// Packages:
import { ITHEMES } from './types'


// Constants:
const THEMES: ITHEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
}


// Exports:
export default THEMES