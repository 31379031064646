// Constants:
const COLORS = {
  PRIMARY: '#46ACC2',
  WHITE: '#F8F8FA',
  BLACK: '#121212'
}


// Exports:
export default COLORS;
