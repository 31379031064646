// Imports:
import BP0 from './buttonPress/0.mp3'
import BP1 from './buttonPress/1.mp3'
import BP2 from './buttonPress/2.mp3'
import BP3 from './buttonPress/3.mp3'
import BP4 from './buttonPress/4.mp3'
import BP5 from './buttonPress/5.mp3'
import BP6 from './buttonPress/6.mp3'

import ST0 from './strikeThrough/0.mp3'
import ST1 from './strikeThrough/1.mp3'
import ST2 from './strikeThrough/2.mp3'
import ST3 from './strikeThrough/3.mp3'
import ST4 from './strikeThrough/4.mp3'
import ST5 from './strikeThrough/5.mp3'
import ST6 from './strikeThrough/6.mp3'


// Exports:
export const buttonPressSoundEffects = [ BP0, BP1, BP2, BP3, BP4, BP5, BP6 ]
export const strikeThroughSoundEffects = [ ST0, ST1, ST2, ST3, ST4, ST5, ST6 ]
